import { render, staticRenderFns } from "./login-input.vue?vue&type=template&id=67c6591b&scoped=true&"
import script from "./login-input.vue?vue&type=script&lang=js&"
export * from "./login-input.vue?vue&type=script&lang=js&"
import style0 from "./login-input.vue?vue&type=style&index=0&id=67c6591b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c6591b",
  null
  
)

export default component.exports